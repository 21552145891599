<template>
    <div class="container-bar-chart">
        <div v-if="loading" class="loading">
            <div class="loading-spinner"></div>
        </div>
        <div class="container-chart" v-else>
            <BarChartV2 :data="data" :labels="labels" :title="title" :data_line="data_line" :labels_line="labels_line" />
        </div>
    </div>
</template>

<script>
import BarChartV2 from "@/components/chart/BarChartV2.vue"; 

export default {
    name: "BarChartData",
    components: {
        BarChartV2, 
    },
    props: ["data", "data_line", "labels_line", 'labels', 'title', 'infoTitle', 'infoValue', 'infoSubtitle', 'loading'],
};
</script>

<style scoped>
.container-bar-chart {
    gap: 8px;
    display: flex;
    flex-direction: column;
}  

.container-chart {
    position: relative;
    width: 100%;
    max-width: 1660px;
}
</style>