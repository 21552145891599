<script>
import { Bar } from 'vue-chartjs';
Chart.pluginService.register({
    beforeDraw: function (chart) {
        var width = chart.chart.width,
            height = chart.chart.height,
            ctx = chart.chart.ctx;
        if (chart.data.datasets[0].subtext) return;
        ctx.clearRect(0, 0, width, height);
        ctx.save();
    }
});

Chart.plugins.register({
    afterDraw: function (chartInstance) {
        if (chartInstance.config.options.showDatapoints) {
            var helpers = Chart.helpers;
            var ctx = chartInstance.chart.ctx;

            // render the value of the chart above the bar
            ctx.font = Chart.helpers.fontString(14, 'normal', 'Inter');
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            ctx.fillStyle = '#111827';

            chartInstance.data.datasets.forEach(function (dataset) {
                if(dataset.type === 'line') return;
                for (var i = 0; i < dataset.data.length; i++) {
                    var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                    var scaleMax = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._yScale.maxHeight;
                    var yPos = (scaleMax - model.y) / scaleMax >= 0.93 ? model.y + 15 : model.y - 5;
                    let value = dataset.data[i];
                    if (value / 1000 >= 1) {
                        value = (value / 1000).toFixed(2) + 'k';
                    } else {
                        value = value.toFixed(2);
                    }
                    ctx.fillText(value, model.x, yPos);
                }
            });
        }
    }
});
export default {
    extends: Bar,
    props: ['data', 'labels', 'title', 'data_line', 'labels_line'],
    data() {
        const line_data = Object.entries(this.data_line).map(([key, data]) => {
            return{
                label: key,
                data: data.dataset,
                type: 'line',
                borderColor: data.color,
                backgroundColor: data.color,
                fill: false,         
                order: 0,  
            }          
        });
        return {
            chartData: {
                labels: this.labels,
                datasets: [
                    {
                        label: this.title,
                        barThickness: 40,
                        order: 1,
                        pointStyle: 'circle',
                        data: this.data,
                        backgroundColor: new Array(this.data.length).fill('#E6EEF9'),
                        borderRadius: 4,
                        borderSkipped: false,
                        hoverOffset: 4,
                    }, 
                    ...line_data
                ],
            },
            chartOptions: {
                maintainAspectRatio: false,
                responsive: true,
                showDatapoints: true,
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            fontColor: '#4B5563',
                            fontFamily: 'Inter',
                            fontSize: 12
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            display: false,
                          
                        },
                        gridLines: {
                            display: false,
                        },
                    }],
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            console.log(tooltipItem)
                            var label = data.datasets[tooltipItem.datasetIndex].label || '';

                            if (label) {
                                label += ': ';
                            }
                            label += tooltipItem.yLabel.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                            });
                            
                            return label;
                        }
                    }
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        pointStyle: 'circle',
                        usePointStyle: true,
                    }
                },
            }
        }
    },
    mounted() {
        this.renderChart(this.chartData, this.chartOptions);
    }
}
</script>