<template>
    <div class="mainx">
        <BaseHeader title="Potencial de receita"></BaseHeader>
        <b-breadcrumb
            :items="[{ text: 'Dashboard', href: '/dashboard' }, { text: $t('views.seller.reports.text_1428'), href: '/relatorios' }, { text: 'Potencial de receita' }]"
            class="bg-white m-0 p-0"></b-breadcrumb>
        <div class="divider"></div>
        <div class="container-x">
            <Container title="Potencial de receita para os próximos meses"
                subtitle="Receitas esperadas para os próximos meses. Essa projeção não contempla inadimplência, reembolso e cashback.">
                <BarChartData 
                :data="bar_chart_data['data'] || []" :labels="bar_chart_data['labels'] || []" 
                :data_line="line_chart_data['data'] || []" :labels_line="line_chart_data['labels'] || []"
                :title="'Total'" :loading="loading"/>
            </Container>
            <div class="box-stateless">
                <Table :columns="['Mês', 'Vendas', 'Cartão de crédito', 'Boleto', 'Internacional', 'Valor Total']"
                    :rows="data"></Table>
            </div>
        </div>
    </div>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue';
import Container from "@/components/Seller/Reports/Container.vue";
import Table from "@/components/Seller/Reports/Table.vue";
import ReportService from "@/services/resources/ReportService";
import BarChartData from "@/components/Seller/Reports/BarChartData.vue";
import moment from 'moment';

const Report = ReportService.build();

export default {
    components: {
        BaseHeader,
        Container,
        Table,
        BarChartData,
    },
    data() {
        return {
            data: [],
            loading: false,
            bar_chart_data: [],
            line_chart_data: [],
        };
    },
    methods: {
        fetchData() {
            this.loading = true;
            Report.search({ url: "sales-availability", startDate: moment().subtract(1, 'month').startOf('month').toISOString(), endDate: moment().endOf('year').toISOString(), type: "full" })
                .then((response) => {
                    this.data = response['sales_grouped_by_month_table'];
                    this.bar_chart_data = response['sales_grouped_by_month_bar_chart'];
                    this.line_chart_data = response['sales_grouped_by_month_line_chart'];
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                });
        }
    },
    mounted() {
        this.fetchData();
    }
};
</script>

<style scoped>
.box-stateless {
    border: 1px solid #D7DAE2;
    box-sizing: border-box;
    border-radius: 8px;
}
.container-x {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 24px;
}
</style>
<style>
.box-stateless {
    table thead tr th:first-child {
        border-top-left-radius: 8px;
    }

    table thead tr th:last-child {
        border-top-right-radius: 8px;
    }
}
</style>